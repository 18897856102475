<template>
    <div class="container-fluid d-flex flex-column">
      <!-- Current Session Display -->
      <div class="row justify-content-center">
        <div class="col-xs-12">
          <!-- <div v-if="mainSession.length > 0" class="session_full" v-if="Number(mainSession[0].percentage) === 100">Session Full</div> -->
          <h1>{{ mainSession?.room || 'Room Name' }}</h1>
          <div class="progress">
            <div class="progress-bar session-progress"
                :style="getProgressBarStyle(mainSession?.percentage || 0)">
            </div>
          </div>
          <div class="labels">
            <span>Empty</span>
            <span>Full</span>
          </div>
        </div>
      </div>
  
      <!-- Additional Sessions -->
      <div class="row d-flex justify-content-center">
        <div class="col-auto">
          <h3>Additional Impact Sessions</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" v-for="(sessionGroup, index) in chunkedSessions" :key="index">
          <div 
            class="session" 
            v-for="session in sessionGroup" 
            :key="session.id">
            <div class="row mt-2">
              <div class="col-lg-2 offset-lg-1">
                <img class="img-fluid" :src="session.speaker_image || 'https://placebeard.it/250/250'" alt="Speaker Image" />
              </div>
              <div class="col-lg-8">
                <div class="session_full" v-if="Number(session.percentage) === 100">Full</div>
                <div class="session_info">
                  <h2>{{ session.speaker_name }} ({{ session.room }})</h2>
                  <p>{{ formatTime(session.start_at) }} | {{ session.name }}</p>
                </div>
                <div class="progress">
                  <div
                    class="progress-bar session-progress"
                    :style="getProgressBarStyle(session.percentage)">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { ref, computed, onMounted, onUnmounted } from "vue";
  import footerImage from "@/assets/images/seek_block_logo.png";
  
  export default {
    data() {
      return {
        footerImage,
      };
    },
    setup() {
        const sessions = ref([]);
        const mainSession = ref(null);
        const intervalId = ref(null);
  
    //   http://localhost:8080/room/155/1/B
    const fetchSessions = async () => {
    try {
        const baseUrl = process.env.VUE_APP_API_URL;
        const urlParts = window.location.pathname.split("/");

        const room = urlParts[urlParts.length - 3];
        const dayNumber = urlParts[urlParts.length - 2];
        const dailyRoundNumber = urlParts[urlParts.length - 1];

        const fullUrl = `${baseUrl}/sessions?day=${dayNumber}&daily_round_number=${dailyRoundNumber}`;
        const response = await axios.get(fullUrl);

        sessions.value = response.data.sort((a, b) => new Date(a.start_at) - new Date(b.start_at));
        mainSession.value = response.data.find((session) => session.room === room);

        // Remove the mainSession from sessions
        sessions.value = sessions.value.filter(session => session.id !== mainSession.value.id);


        } catch (error) {
          console.error("Error fetching sessions:", error);
        }
      };
  
      onMounted(() => {
        fetchSessions();
        intervalId.value = setInterval(fetchSessions, 5000); // Fetch every 5 seconds
      });
  
      onUnmounted(() => {
        if (intervalId.value) clearInterval(intervalId.value);
      });
  
      // Group sessions into chunks for a two-column layout
      const chunkedSessions = computed(() => {
        const chunkSize = Math.ceil(sessions.value.length / 2);
        return [sessions.value.slice(0, chunkSize), sessions.value.slice(chunkSize)];
      });
  
      return {
        sessions,
        mainSession,
        chunkedSessions,
      };
    },
    methods: {
      formatTime(datetime) {
        const date = new Date(datetime);
        return new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        }).format(date).replace(/AM|PM/gi, "").trim();
      },
      getProgressBarStyle(percentage) {
        return {
          width: percentage + "%",
        };
      },
    },
  };
  </script>
  
  <style scoped>
  /* Basic styles for the session list */
  .session {
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    padding: 1rem;
  }
  
  .session-info {
    margin-bottom: 1rem;
  }
  
  .progress-bar-container {
    width: 100%;
    height: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #00aaff;
    transition: width 0.5s ease;
  }
  
  .session_full {
    display: none;
    color: red;
    font-weight: bold;
  }
  
  .session_full.show {
    display: block;
  }
  
  .session-info.full {
    opacity: 0.6;
  }
  </style>
  