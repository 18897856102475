import { createRouter, createWebHistory } from 'vue-router';
import CurrentSessionsForEvent from '../components/CurrentSessionsForEvent/CurrentSessionsForEvent.vue';
import AllSessions from '../components/AllSessions/AllSessions.vue';
import OneSession from '@/components/OneSession/OneSession.vue';

const routes = [
  {
    path: '/:eventSlug/:dayNumber/:dailyRoundNumber',
    name: 'CurrentSessionsForEvent',
    component: CurrentSessionsForEvent
  },
  {
    path: '/sessions/718991fd-b88f-43c1-9650-3c9949f26279',
    name: 'AllSessions',
    component: AllSessions
  },
  {
    path: '/room/:room/:dayNumber/:dailyRoundNumber',
    name: 'OneSession',
    component: OneSession
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
